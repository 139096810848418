import request from '@/api/request.js'; //请求对象
 
const payment = {
   
   logs (params) {
       return request({url: `/admin_payment/readOneUserPaymentLog`,method: 'POST',data:params})
   },
   rechargeAdmin (params) {
       return request({url: `/admin_payment/rechargeAdmin`,method: 'POST',data:params})
   },
}

export default payment;