/**
  * api接口的统一出口
*/
// pms模块接口
import category from '@/api/category.js';
import tag from '@/api/tag.js';
import supplier from '@/api/supplier.js';
import attr from '@/api/attr.js';
import user from '@/api/user.js';
import admin from '@/api/admin.js';
import appversion from '@/api/appversion.js';
import appconfig from '@/api/appconfig.js';
import payment from '@/api/payment.js';
import sku from '@/api/sku.js';
import upload from '@/api/upload.js';
import sounds from '@/api/sounds.js';
// 代理管理
import agent from '@/api/agent.js';
//激活码类型
import code_type from '@/api/code_type.js';
//用户搜索音效关键词分析
import searchSoundLog from '@/api/searchSoundLog.js';
import findSound from '@/api/findSound.js';
import onlineUser from '@/api/onlineUser.js';
import suggestions from '@/api/suggestions.js';

//wms模块接口

// 其他模块的接口……

// 导出接口
export default {
  category,
  tag,
  supplier,
  attr,
  user,
  admin,
  appversion,
  appconfig,
  payment,
  sku,
  upload,
  sounds,

  agent,
  code_type,

  searchSoundLog,
  findSound,
  suggestions,

  onlineUser

}
