import request from '@/api/request.js'; //请求对象
 
 const apis = {
	update (params){
		return request({url: `/appconfig/update`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/appconfig/read`, params:params, method: 'GET'})
	},
 }
 
 export default apis;