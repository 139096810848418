import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	create (params){
		return request({url: `/admin/create`, method: 'POST', data:params})
	},
	delete (params){
		return request({url: `/admin/delete`, method: 'POST', data:params})
	},
	update (params){
		return request({url: `/admin/update`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/admin/read`, params:params, method: 'GET'})
	},
	readOneAdmin (params){
		return request({url: `/admin/readOneAdmin`, params:params, method: 'GET'})
	},
 }
 
 export default apis;