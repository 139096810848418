import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	create (params){
		return request({url: `/agent/create`, method: 'POST', data:params})
	},
	delete (params){
		return request({url: `/agent/delete`, method: 'POST', data:params})
	},
	update (params){
		return request({url: `/agent/update`, method: 'POST', data:params})
	},
	read (){
		return request({url: `/agent/read`, method: 'GET'})
	},
	readOne (params){
		return request({url: `/agent/readOne`, method: 'POST', data:params})
	},
 }
 
 export default apis;