import request from '@/api/request.js'; //请求对象
 
 const apis = {
	
	create (params){
		return request({url: `/code_type/create`, method: 'POST', data:params})
	},
	delete (params){
		return request({url: `/code_type/delete`, method: 'POST', data:params})
	},
	update (params){
		return request({url: `/code_type/update`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/code_type/read`, method: 'POST', data:params})
	},
	readOne (params){
		return request({url: `/code_type/readOne`, method: 'POST', data:params})
	},

	//代理与code type 的关系
	readOneAgentCodeType (params){
		return request({url: `/code_type/ReadOneAgentCodeType`, method: 'POST', data:params})
	},
	addAgentCodeType (params){
		return request({url: `/code_type/addAgentCodeType`, method: 'POST', data:params})
	},
	removeAgentCodeType (params){
		return request({url: `/code_type/removeAgentCodeType`, method: 'POST', data:params})
	},
	updateAgentCodeType (params){
		return request({url: `/code_type/updateAgentCodeType`, method: 'POST', data:params})
	},
 }
 
 export default apis;