import request from '@/api/request.js'; //请求对象
import axios from 'axios';
var instance = axios.create({
    timeout: (1000 * 60 * 20),
	baseURL:'/apis',
    // baseURL:base.getServerInfo().api_server
	headers: { 'content-type': 'multipart/form-data' }
});
 const apis = {
	
	create (params){
		return instance.post(`/admin_sounds/create`,params)
		// return request({url: `/admin_sounds/create`, method: 'POST', data:params})
	},
	update (params){
		return instance.post(`/admin_sounds/update`,params)
		// return request({url: `/admin_sounds/update`, method: 'POST', data:params})
	},
	setSoundsDiscription (params){
		return request({url: `/admin_sounds/setSoundsDiscription`, method: 'POST', data:params})
	},
	remove (params){
		return request({url: `/admin_sounds/remove`, method: 'POST', data:params})
	},
	read (params){
		return request({url: `/admin_sounds/read`,params:params, method: 'GET'})
	},
	readOneSound (params){
		return request({url: `/admin_sounds/ReadOneSound`,params:params, method: 'GET'})
	},
 }
 
 export default apis;